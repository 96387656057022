import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import styled from 'styled-components';

import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, Text } from 'components';
import ChecklistItem from './ChecklistItem';

interface SliderGraphProps {
  label: string;
  title: string;
  labelBot: string;
  reduceLabel: string;
  from: string;
  to: string;
  per: string;
  fromAnswerKey: string;
  toAnswerKey: string;
  imageUrl: string;
  goalsAnswerKey: string;
  goals: { [key: string]: string };
  alt: string;
}

const Label = styled(Text)`
  margin-bottom: 1rem;
  @media ${tablet} {
    margin-bottom: 0.5rem;
  }
`;

const Title = styled(Text)`
  text-align: center;
  @media ${tablet} {
    font-size: 1.625rem;
  }
`;

const LabelBot = styled(Label)`
  margin: 1rem 0;
`;

const GraphContainer = styled.div`
  width: 100%;
  max-width: 33.1875rem;
  padding: 1.5rem 2rem;

  background: #fafafa;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
  border-radius: 16px;

  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${tablet} {
    padding: 1rem;
  }
`;

const FromToText = styled(Text)`
  margin-bottom: 0.75rem;
  span {
    font-weight: 700;
  }
`;

const From = styled.span``;

const To = styled.span``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const GoalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.cultured};
  padding: 1rem 1.5rem;
  border-radius: 16px;
  max-width: 33.1875rem;
  width: 100%;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
`;

const SliderGraph: FC<SliderGraphProps> = ({
  label,
  title,
  labelBot,
  reduceLabel,
  from,
  to,
  per,
  fromAnswerKey,
  toAnswerKey,
  imageUrl,
  goalsAnswerKey,
  goals,
  alt,
}) => {
  const { quiz_answers } = useSelector((state: AppState) => state.user);

  const fromValue = quiz_answers[fromAnswerKey];
  const toValue = quiz_answers[toAnswerKey];
  const goalAnswers: string[] =
    useMemo(
      () =>
        quiz_answers[goalsAnswerKey]?.split('|').filter(a => a !== 'other'),
      [quiz_answers, goalsAnswerKey]
    ) || [];

  const renderGoal = (key: string, index: number) => (
    <ChecklistItem
      label={goals[key]}
      marginBot={index + 1 !== goalAnswers.length}
      key={index}
    />
  );
  return (
    <Container>
      <Label type="bodyM">{label}</Label>
      <Title type="h13Rem">{title}</Title>
      <LabelBot type="bodyM">{labelBot}</LabelBot>
      <GraphContainer>
        <FromToText type={'bodyM'}>
          {reduceLabel} {from} <From>{fromValue}</From> {to} <To>{toValue}</To>{' '}
          {per}
        </FromToText>
        <DynamicImage src={imageUrl} alt={alt} />
      </GraphContainer>
      {!!goalAnswers?.length && (
        <GoalsContainer>{goalAnswers.map(renderGoal)}</GoalsContainer>
      )}
    </Container>
  );
};

export default SliderGraph;
